// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cases-jsx": () => import("./../../../src/pages/cases.jsx" /* webpackChunkName: "component---src-pages-cases-jsx" */),
  "component---src-pages-forecasts-jsx": () => import("./../../../src/pages/forecasts.jsx" /* webpackChunkName: "component---src-pages-forecasts-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modules-page-jsx": () => import("./../../../src/pages/modules-page.jsx" /* webpackChunkName: "component---src-pages-modules-page-jsx" */)
}

